var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Sizing", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v("You can use "),
        _c("code", [_vm._v("size")]),
        _vm._v(" prop of button component to change the size of button group."),
      ]),
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c("vs-button", { attrs: { size: "large" } }, [_vm._v("First")]),
          _c("vs-button", { attrs: { size: "large" } }, [_vm._v("Second")]),
          _c("vs-button", { attrs: { size: "large" } }, [_vm._v("Third")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-group my-4" },
        [
          _c("vs-button", [_vm._v("First")]),
          _c("vs-button", [_vm._v("Second")]),
          _c("vs-button", [_vm._v("Third")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c("vs-button", { attrs: { size: "small" } }, [_vm._v("First")]),
          _c("vs-button", { attrs: { size: "small" } }, [_vm._v("Second")]),
          _c("vs-button", { attrs: { size: "small" } }, [_vm._v("Third")]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="btn-group">\n  <vs-button size="large">First</vs-button>\n  <vs-button size="large">Second</vs-button>\n  <vs-button size="large">Third</vs-button>\n</div>\n\n<div class="btn-group my-4">\n  <vs-button>First</vs-button>\n  <vs-button>Second</vs-button>\n  <vs-button>Third</vs-button>\n</div>\n\n<div class="btn-group">\n  <vs-button size="small">First</vs-button>\n  <vs-button size="small">Second</vs-button>\n  <vs-button size="small">Third</vs-button>\n</div>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }