import { render, staticRenderFns } from "./ButtonGroupBasic.vue?vue&type=template&id=739f8b70&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('739f8b70')) {
      api.createRecord('739f8b70', component.options)
    } else {
      api.reload('739f8b70', component.options)
    }
    module.hot.accept("./ButtonGroupBasic.vue?vue&type=template&id=739f8b70&", function () {
      api.rerender('739f8b70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/vuesax/button-group/ButtonGroupBasic.vue"
export default component.exports