var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Basic", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v("Add "),
        _c("code", [_vm._v("btn-group")]),
        _vm._v(
          " class to wrapper to make all contained button acts as grouped buttons."
        ),
      ]),
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c("vs-button", [_vm._v("First")]),
          _c("vs-button", [_vm._v("Second")]),
          _c("vs-button", [_vm._v("Third")]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="btn-group">\n  <vs-button>First</vs-button>\n  <vs-button>Second</vs-button>\n  <vs-button>Third</vs-button>\n</div>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }