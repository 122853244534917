var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Vertical Variant", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v("You can create vertical variant of button group using "),
        _c("code", [_vm._v("btn-group-vertical")]),
        _vm._v(" class"),
      ]),
      _c(
        "div",
        { staticClass: "btn-group-vertical" },
        [
          _c("vs-button", [_vm._v("Top")]),
          _c("vs-button", [_vm._v("Middle")]),
          _c("vs-button", [_vm._v("Bottom")]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="btn-group-vertical">\n  <vs-button>Top</vs-button>\n  <vs-button>Middle</vs-button>\n  <vs-button>Bottom</vs-button>\n</div>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }