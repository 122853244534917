var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Toolbar", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v("Use flex container on button groups to create button toolbar."),
      ]),
      _c("div", { staticClass: "flex flex-wrap" }, [
        _c(
          "div",
          { staticClass: "btn-group mr-4 mt-4" },
          [
            _c("vs-button", [_vm._v("1")]),
            _c("vs-button", [_vm._v("2")]),
            _c("vs-button", [_vm._v("3")]),
            _c("vs-button", [_vm._v("4")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-group mt-4 mr-4" },
          [
            _c("vs-button", [_vm._v("5")]),
            _c("vs-button", [_vm._v("6")]),
            _c("vs-button", [_vm._v("7")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-group mt-4" },
          [_c("vs-button", [_vm._v("8")])],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<div class="flex flex-wrap">\n  <div class="btn-group">\n    <vs-button>1</vs-button>\n    <vs-button>2</vs-button>\n    <vs-button>3</vs-button>\n    <vs-button>4</vs-button>\n  </div>\n\n  <div class="btn-group ml-4">\n    <vs-button>5</vs-button>\n    <vs-button>6</vs-button>\n    <vs-button>7</vs-button>\n  </div>\n\n  <div class="btn-group ml-4">\n    <vs-button>8</vs-button>\n  </div>\n</div>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }